import React from "react";

const UnsupportedBrowser = () => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Unsupported Browser</h1>
      <p>
        Access to this platform is restricted on Kiwi Browser. Please use a
        supported browser on your mobile device.
      </p>
    </div>
  );
};

export default UnsupportedBrowser;
