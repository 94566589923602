import React from "react";
import "./UnsupportedDevice.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
const UnsupportedDevice = () => {
  return (
    <div style={{ textAlign: "center", padding: "20px" }} className="unsupport">
      <h1>
        Unsupported Device <FontAwesomeIcon icon={faCircleInfo} />
      </h1>
      <p>
        This platform is only accessible on mobile devices. Please open it on
        your mobile phone.
      </p>
    </div>
  );
};

export default UnsupportedDevice;
